import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import IndexHeader from "components/Headers/IndexHeader";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function TermsOfService() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="wrapper">
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">TERMS OF SERVICE</h2>
                <h5 className="description">
                <h4>ONLINE TERMS OF USE</h4>
These terms and conditions of use (“Terms of Use”) govern your use of our online interfaces and properties (e.g., websites and mobile applications) owned and controlled by Loyalter, LLC. a Georgia Domestic Limited Liability Company (“Company”), including the www.loyalter.com website (collectively, the “Site”), Loyalter Merchant App, and Loyalter Checkin App (“App). Your compliance with these Terms of Use is a condition to your use of the Site. If you do not agree to be bound by the Terms of Use, promptly exit this Site. Please also consult our Privacy Policy for a description of our privacy practices and policies.
Binding Arbitration. These Terms of Use provide that all disputes between you and Company that in any way relate to these Terms of Use or your use of the Site will be resolved by BINDING ARBITRATION. ACCORDINGLY, YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT (INCLUDING IN A CLASS ACTION PROCEEDING) to assert or defend your rights under these Terms of Use (except for matters that may be taken to small claims court). Your rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge or jury and your claims cannot be brought as a class action.
<br/>1. Ownership of the Site
All pages within this Site and any material made available for download are the property of Company, or its licensors or suppliers, as applicable. The contents of the Site and App, including without limitation all data, files, documents, text, photographs, images, audio, and video, and any materials accessed through or made available for use or download through this Site (“Content”) may not be copied, distributed, modified, reproduced, published nor used, in whole or in part, except for purposes authorized by these Terms of Use or otherwise approved in writing by Company. You may not frame or utilize framing techniques to enclose, or deep link to, any name, trademarks, service marks, logo, Content, or other proprietary information (including images, text, page layout, or form) of Company without our express written consent.
<br/>2. Site Access, Security, and Restrictions; Passwords
You are prohibited from violating or attempting to violate the security of the Site and App, including, without limitation, (a) accessing data not intended for such user or logging onto a server or an account which the user is not authorized to access; (b) attempting to probe, scan, or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; or (c) accessing or using the Site or any portion thereof without authorization, in violation of these Terms of Use or in violation of applicable law.
You may not use any scraper, crawler, spider, robot, or other automated means of any kind to access or copy data on the Site, deep-link to any feature or content on the Site, bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Site.
Violations of system or network security may result in civil or criminal liability. Company will investigate occurrences that may involve such violations and may involve and cooperate with law enforcement authorities in prosecuting users who are involved in such violations. You agree not to use any device, software, or routine to interfere or attempt to interfere with the proper working of this Site or any activity being conducted on this Site.
In the event access to the Site or a portion thereof is limited requiring a user ID and password (“Protected Areas”), you agree to access Protected Areas using only your user ID and password as provided to you by Company. You agree to protect the confidentiality of your user ID and password and not to share or disclose your user ID or password to any third party. You agree that you are fully responsible for all activity occurring under your user ID. Your access to the Site may be revoked by Company at any time with or without cause. You agree to defend, indemnify, and hold Company harmless from and against all third-party claims, damages, and expenses (including reasonable attorneys’ fees) against or incurred by Company arising out of your breach of these Terms of Use or violation of applicable law, your use or access of the Site, or access by anyone accessing the Site using your user ID and password.
Company reserves the right to fully cooperate with any law enforcement authorities or court order requesting or directing Company to disclose the identity of anyone posting any e-mail or other messages, or publishing or otherwise making available any other user-generated content that is believed or alleged (reasonably or not) to violate these Terms of Use or any Applicable Law.
<br/>3. Accuracy and Integrity of Information
Although Company attempts to ensure the integrity and accuracy of the Site, it makes no representations, warranties, or guarantees whatsoever as to the correctness or accuracy of the Site and Content thereon. The Site may include typographical errors, inaccuracies, or other errors, and unauthorized additions, deletions, and alterations could be made to the Site by third parties. In the event that an inaccuracy arises, please inform the Company so that it can be corrected. Company reserves the right to unilaterally correct any inaccuracies on the Site without notice. Information contained on the Site may be changed or updated without notice. Additionally, Company shall have no responsibility or liability for information or Content posted to the Site from any non-Company affiliated third party.
<br/>4. Modifications to Products and Prices
Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue any of our products (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third party for any modification, price change, suspension, or discontinuance of our products.
<br/>5. Typographical Errors and Incorrect Pricing
In the event a product or service is listed at an incorrect price due to typographical error or error in pricing information received from our suppliers, we shall have the right to refuse or cancel any orders placed for any product or service listed at the incorrect price. We shall have the right to refuse or cancel any such orders whether or not the order has been confirmed and your credit or debit card charged. If your credit or debit card has already been charged for the purchase and your order is canceled, we shall promptly issue a credit to your credit or debit card account in the amount of the incorrect price.
<br/>6. Order Acceptance
We reserve the right, at our sole discretion, to refuse or cancel any order for any reason. Your account may also be restricted or terminated for any reason, at our sole discretion. For your convenience, you will not be charged until your payment method is authorized, the order information is verified for accuracy, and your order is shipped (except for orders, including pre-orders, paid for with a gift card which are charged at the time you place your order, not when your order is shipped). Some situations that may result in your order being canceled include limitations on quantities available for purchase, inaccuracies or errors in product or pricing information, or problems identified by our credit and fraud avoidance department. We may also require additional verifications or information before accepting any order. We will contact you if any portion of your order is canceled or if additional information is required to accept your order. If your order is canceled after your credit card (or other payment account) has been charged, we will issue a credit to your credit card (or other applicable payment account) in the amount of the charge. When an order is placed, it will be shipped to an address designated by the purchaser as long as that shipping address is compliant with the shipping restrictions contained on this Site. All purchases from this Site are made pursuant to a shipment contract. As a result, risk of loss and title for items purchased from this Site pass to you upon delivery of the items to the carrier. You are responsible for filing any claims with carriers for damaged and/or lost shipments.
<br/>7. Exchanges and Refunds, Quantity Limits, and Sale Restrictions
Exchanges and refunds are subject to the terms outlined in our Order section under Customer Service. Company reserves the right, at our sole discretion, to limit the quantity of items purchased per person, per household, or per order. These restrictions may be applicable to orders placed by the same account, using the same credit card, and that use the same billing and/or shipping address. We will provide notification to the customer should such limits be applied. Please note that certain orders constitute improper use of the Company and its services.
We reserve the right, at our sole discretion, but are not obligated, to limit the sales of our products to any person, geographic region, or jurisdiction, and we reserve the right to refuse or cancel any order for any reason. We may exercise this right on a case-by-case basis. Your account may also be restricted or terminated for any reason, at our sole discretion. Company also reserves the right, at our sole discretion, to prohibit sales to dealers or resellers. For purposes of these Terms of Use, reselling shall be defined as purchasing or intending to purchase any product(s) from Company for the purpose of engaging in a commercial sale of that same product(s) with a third party. All descriptions of products or product pricing are subject to change at any time without notice, at our sole discretion. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this Site is void where prohibited.
<br/>8. Online Payments
You may purchase products on the Site. We accept credit and debit cards issued by U.S. banks. If a credit card account is being used for a transaction, then the Company may obtain pre-approval for an amount up to the amount of the payment.
You represent and warrant that if you are making online payments that (i) any credit card, debit card, and bank account information you supply is true, correct, and complete, (ii) charges incurred by you will be honored by your credit/debit card company or bank, (iii) you will pay the charges incurred by you in the amounts posted, including any applicable taxes, and (iv) you are the person in whose name the card was issued and you are authorized to make a purchase or other transaction with the relevant credit card and credit card information.
<br/>9. Links to Other Sites
Company makes no representations whatsoever about any other website that you may access through this Site. When you access a non-Company website, please understand that it is independent from Company and that Company has no control over the content on that website. In addition, a link to a non-Company website does not mean that Company endorses or accepts any responsibility for the content or the use of the linked website. It is up to you to take precautions to ensure that whatever you select for your use or download is free of such items as viruses, worms, Trojan horses, and other items of a destructive nature. If you decide to access any of the third-party websites linked to this Site, you do this entirely at your own risk. Running or displaying this Site or any material displayed on this Site in frame or through similar means on another Site without our prior written permission is prohibited. Any permitted links to this Site must comply with all applicable laws, rules, and regulations.
<br/>10. Inappropriate Material
You are prohibited from posting or transmitting any unlawful, threatening, defamatory, libelous, obscene, pornographic, or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense or give rise to civil liability or other violate any law. In addition to any remedies that we may have at law or in equity, if we reasonably determine that you have violated or are likely to violate the foregoing prohibitions, we may take any action we reasonably deem necessary to cure or prevent the violation, including without limitation the immediate removal from this Site of the related materials. We will fully cooperate with any law enforcement authorities or court order or subpoena requesting or directing us to disclose the identity of anyone or anything posting such materials.
                  <br />11. Claims of Copyright Infringement
                  
We disclaim any responsibility or liability for copyrighted materials posted on our site. If you believe that your work has been copied in a manner that constitutes copyright infringement, please follow the procedures set forth below.
The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If you believe in good faith that materials available on the website infringe your copyright, you (or your agent) may send loyalter.com a notice at the address listed below requesting that we remove the material or block access to it, but you must include the following information:
<br/>1. Identification of the copyrighted work that you believe to be infringed. Please describe the work, and where possible include a copy or the location (e.g., URL) of an authorized version of the work.
<br/>2. Identification of the content that you believe to be infringing and its location. Please describe the content in as much detail as possible, and provide us with its URL or any other pertinent information that will allow us to locate the content.
<br/>3. Your name, address, telephone number, and (if available) email address.
<br/>4. A statement that you have a good faith belief that the use of the work you are reporting is not authorized by the copyright owner, its agent or the law.
<br/>5. A statement that the information you have supplied is accurate, and indicating under penalty of perjury that you are the copyright owner or authorized representative.
<br/>6. A signature or the electronic equivalent from the copyright holder or authorized representative.
If you believe in good faith that someone has wrongly filed a notice of copyright infringement against you, the DMCA permits you to send us a counter-notice. Notices and counter-notices must meet the then-current statutory requirements imposed by the DMCA. See http://www.copyright.gov for details. Notices and counter-notices should be sent to: Copyright Department, c/o Loyalter LLC, 371 Marble Springs Rd, Lilburn GA 30047 or to support@loyalter.com. We suggest that you consult your legal advisor before filing a notice or counter-notice.
<br/>12. Indemnification
To the maximum extent possible under applicable law, you agree to indemnify and hold harmless the Company from and against any and all claims, demands, threats, suits, or proceedings and pay all resulting losses, liabilities, damages, costs, or expenses (including without limitation, reasonable attorneys’ fees and expenses) arising out of, resulting from, or related to your breach of these terms of use and/or your access to use or misuse of this website. The Company reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this section. In such base, you agree to cooperate with any reasonable requests assisting the Company’s defense of such matter.
<br/>13. Disclaimer of Warranties
COMPANY DOES NOT WARRANT THAT ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SITE WILL BE CORRECTED. THIS SITE IS PROVIDED “AS IS,” WITH ALL FAULTS, WITH NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUALITY OF INFORMATION, QUIET ENJOYMENT, AND TITLE/NON-INFRINGEMENT. COMPANY DOES NOT WARRANT THE ACCURACY, COMPLETENESS, OR TIMELINESS OF THE INFORMATION OBTAINED THROUGH THE SITE.
YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THIS SITE AND LINKED WEBSITES. COMPANY DOES NOT WARRANT THAT FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS, TROJAN HORSES, OR OTHER DESTRUCTIVE PROGRAMMING. YOU ARE RESPONSIBLE FOR IMPLEMENTING PROCEDURES SUFFICIENT TO SATISFY YOUR NEEDS FOR DATA BACK UP AND SECURITY.
<br/>14. Limitation of Liability Regarding Use of Site
COMPANY AND ANY THIRD PARTIES MENTIONED ON THIS SITE ARE NEITHER RESPONSIBLE NOR LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOST DATA, OR BUSINESS INTERRUPTION) ARISING OUT OF OR RELATING IN ANY WAY TO THE SITE, SITE-RELATED SERVICES, CONTENT, OR INFORMATION CONTAINED WITHIN THE SITE, AND/OR ANY LINKED WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE, SITE-RELATED SERVICES, AND/OR LINKED WEBSITES IS TO STOP USING THE SITE AND/OR THOSE SERVICES. TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF LIABILITY ARE NOT ENFORCEABLE, THE MAXIMUM AGGREGATE LIABILITY OF COMPANY TO YOU WITH RESPECT TO YOUR USE OF THIS SITE IS $100 (ONE HUNDRED DOLLARS).
<br/>15. Revisions; General.
Company reserves the right, in its sole discretion, to terminate your access to all or part of this Site, with or without cause, and with or without notice. In the event that any of the Terms of Use are held by a court or other tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary so that these Terms of Use shall otherwise remain in full force and effect. These Terms of Use constitute the entire agreement between Company and you pertaining to the subject matter hereof. In its sole discretion, Company may from time-to-time revise these Terms of Use by updating this posting. You should, therefore, periodically visit this page to review the current Terms of Use, so you are aware of any such revisions to which you are bound. Your continued use of the Site after revisions to these Terms of Use shall constitute your agreement to the revised Terms of Use. Certain provisions of these Terms of Use may be superseded by expressly designated legal notices or terms located on particular pages within this Site. Exclusive jurisdiction and venue for any claims arising under these Terms of Use shall be in state or federal courts located in and near Atlanta, Georgia, and these Terms of Use shall be governed by the laws of the State of Georgia without regard to any conflicts of law principles.

                </h5>
              </Col>
            </Row>

          </Container>
        </div>
    
        <DefaultFooter />
      </div>
    </>
  );
}

export default TermsOfService;
