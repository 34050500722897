import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import IndexHeader from "components/Headers/IndexHeader";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function PrivacyPolicy() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="wrapper">
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">PRIVACY POLICY</h2>
                <h5 className="description">
                This Privacy Policy describes the privacy practices of Loyalter, LLC. and our various services and programs (“Loyalter”, “we”, “us” and/or “our”). We have created this Privacy Policy to explain how we collect, use, share, disclose, and safeguard personal information in connection with operating our businesses, engaging consumers, and marketing and improving our products and services through our online interfaces owned and controlled by us, including www.loyalter.com (collectively herein referred to as the “Site”, and Loyalter Merchant App, Loyalter Checkin App.) This Privacy Policy also identifies the choices available to you regarding our handling of the information
                </h5>
              </Col>
            </Row>

            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title">HOW WE COLLECT INFORMATION</h3>
                <h5 className="description">
                We may collect information about you by various means, including:
Directly from you
Through our websites, mobile apps, and other connected devices and digital means
In-person, including or via our promotional events, sweepstakes, and contests
By combining information from different sources, including online and offline data
From our vendors and business partners
From other sources and platforms that you may use to engage with Loyalter.
TYPES OF INFORMATION WE COLLECT 
The types of information we may collect include:
Your contact information, such as name, birthday, postal or email address, and phone number
Contact information for friends or other people you would like us to contact
Demographic information, such as your age, gender, or household income
Shopping behavior or preferences
Billing and shipping information
Username and password for the account you may establish on a Loyalter website
Content you provide (such as photographs, articles, and comments)
Content you make available through social media accounts (e.g., when you sign in using social media plug-ins)
Information you choose to provide in connection with an application for employment
Device-specific information (such as unique device identifiers and mobile network information including phone number)
Personal information stored locally on your device using mechanisms such as browser web storage (including HTML 5) and application data caches.
COOKIES AND OTHER INFORMATION COLLECTED BY AUTOMATED MEANS 
We, our service providers, and our business partners may also collect certain information about the use of our websites, mobile apps, social media presence, and Internet-enabled services and platforms by automated means, such as cookies, web beacons, and other technologies. We and our service providers and business partners may collect information about your online activities over time and across third-party websites.
The information that may be collected by automated means includes:
URLs that refer visitors to our websites
IP addresses and other details of devices you use to connect with Loyalter, including via our websites or other connected devices and services
Search terms used to reach our online content
Details about your interaction with our websites, mobile apps, social media platforms, or other connected devices and services (such as the date, time, and length of visits; specific pages accessed during the visits; and the frequency of the visits)
Interaction and viewing of advertisements and content
Geo-location data
Transaction information including items purchased and date and time of your transaction.
HOW WE USE YOUR INFORMATION
We may use the information we collect to improve and develop new goods and services, and to enhance our customers’ experience when visiting our websites, mobile apps, social media, and connected devices, services, and platforms. Examples include:
Responding to your requests, questions and comments, and providing customer support
Facilitating your engagement with us via connected devices and servicesT
Tailoring the content we display to you in our communications, including advertising, and offering products and services that we believe may be of interest to you
Operating, evaluating, and improving our business, our websites and mobile apps, and other products and services we offer (including developing new products and services)
Establishing and maintaining any account you create on our websites or mobile apps
Fulfilling, managing, and sending you information about your orders, including processing payments
Offering and administering promotions, sweepstakes, and contests
Conducting market research
Analyzing and enhancing our communications and strategies (including by identifying when emails sent to you have been received and read)
Providing personally relevant product features, such as tailored search results and advertising
Generating anonymized data for further data analytics
Accepting and evaluating employment applications
Informing you about changes to this Privacy Policy, our Terms of Use, and other policies
Enforcing our contractual rights
Complying with applicable legal requirements, industry standards, and our policies.

                </h5>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">ONLINE ADVERTISING </h2>
                <h5 className="description">
ONLINE ADVERTISING 
You may see Loyalter advertising on various websites, web pages, social media, and other platforms. Please note that the nature of Internet media buys is such that we are not always able to have full information about the platforms on which our advertising may run. These platforms in some cases may not be aligned with our values as a company. If you believe that our advertising is displayed on platforms that promote inappropriate or offensive content, please contact us at support@loyalter.com.
We may serve advertisements and allow third parties to serve advertisements through our websites and mobile apps. These advertisements are sometimes targeted and served to particular users and may come from third-party companies called “ad networks.” Ad networks include third-party ad servers, ad agencies, ad technology vendors, and research firms. We are not responsible for the privacy practices of these ad networks and other parties. Advertisements served through our website and mobile apps may be targeted to users who fit a certain general profile category, which may be inferred from information you provide to us, based on your website or mobile app usage patterns, or based on your activity on other website or online services. To learn more about interest-based advertisements and your opt-out rights and options, visit the Digital Advertising Alliance and the Network Advertising Initiative. Please note that if you choose to opt out, you may continue to see ads on our Site or from us on third-party sites, but they will not be based on how you browse and shop. 
HOW WE SHARE YOUR INFORMATION 
We may share your information in compliance with our Privacy Policy with our affiliates, service providers, or partners, including various retailers and merchants. We collaborate with our affiliates, service providers, and business partners on:
Customer service and support
Payment processing and order fulfillment
Product and service development
Surveys and market research
Advertising, promotion, and marketing
Data analytics
Support of our business operations.
We also may disclose information about you (i) if we are required to do so by law or legal process, (ii) when we believe disclosure is necessary to prevent harm or financial loss, or (iii) in connection with an investigation of suspected or actual fraudulent or illegal activity.
We reserve the right to transfer the information we maintain in the event we sell or transfer all or a portion of our business or assets. If we engage in such a sale or transfer, we will make reasonable efforts to direct the recipient to use your personal information in a manner that is consistent with this Privacy Policy. After such a sale or transfer, you may contact the recipient with any inquiries concerning the recipient’s privacy practices.
MARKETING EMAILS 
You may choose not to receive marketing emails from us by clicking on the unsubscribe link in the marketing emails you receive from us. Even if you opt out of receiving such communications, we will continue sending you non-marketing email communications, such as order confirmations or information about changes in our website terms.
COOKIES
Web browsers may offer users the ability to disable receiving certain types of cookies; however, if cookies are disabled, some features or functionality of our websites and mobile apps may not function correctly.
GEO-LOCATION
You may stop sharing your location data by adjusting your mobile device’s location services settings. For instruction on changing the relevant settings, please contact your service provider or device manufacturer. 
ACCESS AND CORRECTION 
You may (a) request access to the information we maintain about you, and the details about our processing of that data, (b) request that we update or correct your information, or (c) request that we delete the information we maintain about you. You may make a request by contacting us at support@loyalter.com.
In some circumstances, including where we are specifically permitted to do so by applicable law or your request is inconsistent with our legal obligations or our policies, we may choose not to comply with your request or provide a partial response. If this is the case, we will notify you of our decision.
CHILDREN 
We do not knowingly collect personal data from children under the age of 13 on our websites or mobile apps. If you have reason to believe that a child under the age of 13 has provided personal data to us through our websites or mobile apps, please contact us, and we will endeavor to delete that information from our databases.
LINKS TO OTHER WEBSITES AND THIRD PARTY CONTENT 
We may provide links to other websites, services, and applications, such as Facebook, that are not operated or controlled by Loyalter (the “Third-Party Services”). These Third-Party Services also may include an activity feed, social media buttons, and widgets, such as the Facebook “Like” button or the “Share This” button.
This Privacy Policy does not apply to the Third-Party Services. While we attempt to facilitate access only to those Third-Party Services that share our respect for your privacy, we cannot take responsibility for the content, privacy policies, or practices of those Third-Party Services. We encourage you to review and understand the privacy practices of any Third-Party Services before providing any information to or through them. Your interactions with these features are governed by the privacy policy of the Third-Party Service that provides the feature.
CALIFORNIA VISITORS
If you are a California resident, please review the California Privacy Notice, below.
INTERNATIONAL VISITORS
The Website is hosted and operated in the United States.  By using the Website, you are consenting to the transfer of your Personally Identifiable Information to the United States.  If you are accessing the Website from outside of the United States, please be advised that United States’ law may not offer the same privacy protections as the laws of your jurisdiction.
How We Protect Information
We use industry standard physical, technical, and administrative security measures and safeguards to protect the confidentiality and security of your personal information. However, since the Internet is not a 100% secure environment, we cannot guarantee, ensure, or warrant the security of any information you transmit to us. There is no guarantee that information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards, and we bear no liability for uses or disclosures of personal information or other data arising in connection with theft of the information or other malicious actions.
It is your responsibility to protect the security of your login information. Please note that e-mails and other communications you send to us through our Site are not encrypted, and we strongly advise you not to communicate any confidential information through these means.
UPDATES TO OUR PRIVACY POLICY 
Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your consent in accordance with applicable law. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice (including, for certain services, email notification of privacy policy changes).
PRIVACY NOTICE FOR CALIFORNIA RESIDENTS
This Privacy Notice for California Residents supplements the information contained in Loyalter' Privacy Policy and applies solely to external contacts who reside in the State of California (“you” or “Consumer”).  We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this Notice.


                </h5>
              </Col>
              
              <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title">INFORMATION WE COLLECT AND DISCLOSE</h3>
                <h5 className="description">
                We collect information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device (“Personal Information”). Personal Information does not include:
Publicly available information from government records.
Deidentified or aggregated consumer information.
In particular, we have collected the following categories of Personal Information from our Consumers within the last twelve (12) months:
Category
Collected
Disclosed
Identifiers, such as your name, address, phone number, email address, date of birth, or other similar identifiers.
Yes directly from you.
Disclosed to trusted service providers to fulfil your request or transaction, including, for example, payment processing, or delivery or shipping services.
Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)), such as birthdate, contact information, and payment information. Some personal information included in this category may overlap with other categories.
Yes directly from you.
Disclosed to trusted service providers to fulfil your request or transaction, including, for example, payment processing, or delivery or shipping services.
 
Protected classification characteristics under California or federal law, such as your age, race, color, ancestry, national origin, citizenship, sexual orientation, or other similar protected information.
No
 
Commercial information, such as records of personal property, products or services purchased, or other consuming histories or tendencies.
Yes directly from you.
Not disclosed.
Biometric information, such as genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as fingerprints.
No
 
Internet or other similar network activity, such as browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement.
Yes
Not disclosed.
Geolocation data, such as physical location or movements.
No
 
Sensory data, such as audio, electronic, visual, thermal, olfactory, or similar information.
No
 
Professional or employment-related information. Such as current or past job history or performance evaluations.
No
 
Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)), such as educational history or degree (for applicants).
No
 
Inferences drawn from other personal information, such as predictions about your interests and preferences.
No
 

 

                </h5>
              </Col>
           
              </Row>

              <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title">USE OF PERSONAL INFORMATION</h3>
                <h5 className="description">
                We use your information in accordance with the Section “Why do we collect personal data” in our Privacy Policy.
We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.
SHARING PERSONAL INFORMATION
We may disclose your personal information to a third party for a business purpose.  When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract. Please see above for the disclosures we have made in the preceding twelve (12) months for a business purpose.
Sales of Personal Information
In the preceding twelve (12) months, Company has not sold personal information.
                </h5>
              </Col>
           
              </Row>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h3 className="title">YOUR RIGHTS AND CHOICES</h3>
                <h5 className="description">
                The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.
The Right to Know
You have the right to request any or all of the following information relating to your personal information we have collected and disclosed in the last 12 months, upon verification of your identity:
-   	The specific pieces of information we have collected about you;
-   	The categories of Personal Information we have collected about you;
-   	The categories of sources of the Personal Information;
-   	The categories of Personal Information that we have disclosed to third parties for a business purpose, and the categories of recipients to whom this information was disclosed;
-   	The categories of Personal Information we have sold and the categories of third parties to whom the information was sold; and
-   	The business or commercial purposes for collecting or selling the Personal Information
The Right to Request Deletion
You have the right to request the deletion of Personal Information we have collected from you, subject to certain exceptions.
The Right to Opt Out of Personal Information Sales
You have the right to request the deletion of Personal Information we have collected about you to third parties now or in the future.
The Right to Non-Discrimination
You have the right not to receive discriminatory treatment for exercising these rights.  However, please note that if the exercise of these rights limits our ability to process Personal Information, we may no longer be able to provide you our products and services or engage with you in the same manner.
“Shine the Light”
California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to support@loyalter.com

                </h5>
              </Col>
            </Row>
          </Container>
        </div>
    
        <DefaultFooter />
      </div>
    </>
  );
}

export default PrivacyPolicy;
