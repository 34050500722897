/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  href="#home"
                  target="_blank"
                >
                  Loyalter
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  target="_blank"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="#services"
                  target="_blank"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="#services"
                  target="_blank"
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/terms-of-service"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="/sms-terms-of-service"
                  target="_blank"
                >
                  SMS Terms of Service
                </a>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()} {" "}
            <a
              href="#home"
              target="_blank"
            >
              Loyalter
            </a>
            . All Rights Reserved{" "}
            {/* <a
              href="https://www.creative-tim.com?ref=nukr-default-footer"
              target="_blank"
            >
              Creative Tim
            </a> */}
            .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
